import Pic1 from "../images/app-pic1.PNG";
import Pic2 from "../images/app-pic2.jpg";
import Pic3 from "../images/app-pic3.jpg";
import Pic4 from "../images/app-pic4.jpg";
import Pic5 from "../images/app-pic5.jpg";
import knives1 from "../images/knives.PNG";
import knives2 from "../images/knives2.PNG";
import knives3 from "../images/knives3.PNG";
import knives4 from "../images/knives4.PNG";
import medics1 from "../images/medics1.PNG";
import medics2 from "../images/medics2.PNG";
import medics3 from "../images/medics3.PNG";
import medics4 from "../images/medics4.PNG";
import medics5 from "../images/medics5.PNG";
import medics6 from "../images/medics6.PNG";
import medics7 from "../images/medics7.PNG";
import medics8 from "../images/medics8.PNG";

export const workInfo = [
  {
    title: "Hotspot App",
    description: "Fullstack app",
    titlePic: Pic1,
    key: 1,

    modalInfo: {
      title: "Hotspot Web-App Design",
      description:
        "Built with a React.js frontend and a Nodejs backend. Integradable with with a Mikrotik router and Freeradius server to provide Authentication, Authorization and Accounting to hoptspot Clients.Once integrated with a Mikrotik Router hotspot clients earn bundles by watching short advertisments and use the earned bundles to go online",
      tableInfo: {
        type: "Web-app",
        stack: "MERN (React, Node, Express, MondoDB)",
        radius: "Freeradius & MYSQL",
        Hosting: "Digital Ocean (Ubuntu VPC)",
        Visit: "Livecrib",
        url: "https://livecrib.rent/",
      },
      pics: [Pic1, Pic2, Pic3, Pic4, Pic5],
      modalKey: 1,
    },
  },
  {
    title: "Knives & Forks SteakHouse",
    description: "Wordpress website",
    titlePic: knives1,
    key: 2,

    modalInfo: {
      title: "Knives & Forks SteakHouse",
      description:
        "With a user-friendly interface and seamless navigation, exploring knives & Forks is a breeze. Whether you're browsing on your desktop, tablet, or smartphone, my responsive design ensures an optimal viewing experience across all devices.",
      tableInfo: {
        type: "A Mock steakhouse Website",
        stack: "WordPress",
        Hosting: "Digital Ocean (Ubuntu VPC)",
        Visit: "wp.livecrib.rent",
        url: "https://wp.livecrib.rent/",
      },
      pics: [knives1, knives2, knives3, knives4],
      modalKey: 2,
    },
  },
  {
    title: "USSD Medics App",
    description: "Fullstack App",
    titlePic: medics3,
    key: 3,

    modalInfo: {
      title: "USSD Medics App",
      description:
        "Seamlessly schedule a doctor's appointment with the specialist of your choice via a USSD (Unstructured Supplementary Service Data) and get a Text message confirmation of the appointmennt on your phone, with a user-friendly admin and doctor's web interface to manage doctors and appointments.",
      tableInfo: {
        type: "USSD and Web App",
        stack: "Reactjs, Nodejs, Express, MongoDB, Mysql",
        Hosting: "Digital Ocean (Ubuntu VPC)",
        Visit: "ussd.livecribauth.com",
        url: "https://ussd.livecribauth.com/",
      },
      variant: "dark",
      pics: [
        medics7,
        medics8,
        medics1,
        medics2,
        medics3,
        medics4,
        medics5,
        medics6,
      ],
      modalKey: 3,
    },
  },
];
